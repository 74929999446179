var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ORGANIZATION')) + " (*)"),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"allowNone":true,"organization":_vm.salesInvoice.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.salesInvoice.id},on:{"organizationChanged":function (organizationId) {
          _vm.salesInvoice.organization.id = organizationId;
          _vm.salesInvoice.allowedLocations = [];
          _vm.salesInvoice.salesOrder.id = null;
          _vm.salesInvoice.recipient.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.LOCATION'))),"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.salesInvoice.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.salesInvoice.organization.id},on:{"locationsChanged":function (locations) {
          _vm.salesInvoice.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SALES_ORDERS))?_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.SALES_ORDERS'))),"placeholder":_vm.$t('COMMON.SALES_ORDERS')}},[_c('sales-order-selector',{attrs:{"allowNone":true,"salesOrder":_vm.salesInvoice.salesOrder.id,"filterable":true,"showAll":false,"disabled":!!_vm.salesInvoice.id,"filterOrganization":_vm.salesInvoice.organization.id,"filterStatus":_vm.ORDER_STATUS_VALIDATED},on:{"salesOrderChanged":function (salesOrderId, salesOrder) {
          _vm.salesInvoice.salesOrder.id = salesOrderId;
          if (salesOrder) {
            _vm.salesInvoice.recipient.id = salesOrder.recipient.id;

            _vm.salesInvoice.billing_entity_type =
              salesOrder.billing_entity_type;
            _vm.salesInvoice.billing_firstname = salesOrder.billing_firstname;
            _vm.salesInvoice.billing_lastname = salesOrder.billing_lastname;
            _vm.salesInvoice.billing_company_name =
              salesOrder.billing_company_name;
            _vm.salesInvoice.billing_email = salesOrder.billing_email;
            _vm.salesInvoice.billing_country = salesOrder.billing_country;
            _vm.salesInvoice.billing_state = salesOrder.billing_state;
            _vm.salesInvoice.billing_city = salesOrder.billing_city;
            _vm.salesInvoice.billing_zipcode = salesOrder.billing_zipcode;
            _vm.salesInvoice.billing_address = salesOrder.billing_address;
          }
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),_c('base-input',{attrs:{"label":((_vm.$t('SALES_INVOICES.EXPIRATION_TIME')) + " (*)"),"placeholder":_vm.$t('SALES_INVOICES.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
        allowInput: true,
        minDate: 'today',
        locale: _vm.$flatPickrLocale(),
      }},on:{"on-change":function () {
          _vm.onFormChanged();
        }},model:{value:(_vm.salesInvoice.expiration_time),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "expiration_time", $$v)},expression:"salesInvoice.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}}),_c('base-input',{attrs:{"label":((_vm.$t("SALES_INVOICES.SELECT_CUSTOMERS")) + " (*)")}},[_c('customer-selector',{attrs:{"allowNone":true,"customer":_vm.salesInvoice.recipient.id,"filterable":true,"showAll":false,"filterOrganization":_vm.salesInvoice.organization.id,"disabled":!!_vm.salesInvoice.id},on:{"customerChanged":function (customerId, customer) {
          _vm.salesInvoice.recipient.id = customerId;
          if (customer) {
            _vm.salesInvoice.billing_entity_type = customer.customer_type;
            _vm.salesInvoice.billing_firstname = customer.firstname;
            _vm.salesInvoice.billing_lastname = customer.lastname;
            _vm.salesInvoice.billing_company_name = customer.company_name;
            _vm.salesInvoice.billing_email = customer.email;
            _vm.salesInvoice.billing_country = customer.country;
            _vm.salesInvoice.billing_state = customer.state;
            _vm.salesInvoice.billing_city = customer.city;
            _vm.salesInvoice.billing_zipcode = customer.zipcode;
            _vm.salesInvoice.billing_address = customer.address;
          }
          _vm.onFormChanged();
        }}})],1),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.NOTE")))]),_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.salesInvoice.excerpt),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "excerpt", $$v)},expression:"salesInvoice.excerpt"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_INFORMATIONS")))]),_c('base-input',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":_vm.onFormChanged}},[_c('el-select',{attrs:{"name":"Type"},model:{value:(_vm.salesInvoice.billing_entity_type),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "billing_entity_type", $$v)},expression:"salesInvoice.billing_entity_type"}},[_c('el-option',{attrs:{"value":_vm.BILLING_ENTITY_TYPE_INDIVIDUAL,"label":_vm.$t('COMMON.BILLING_ENTITY_TYPE_INDIVIDUAL')}}),_c('el-option',{attrs:{"value":_vm.BILLING_ENTITY_TYPE_COMPANY,"label":_vm.$t('COMMON.BILLING_ENTITY_TYPE_COMPANY')}})],1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_entity_type}}),(
      _vm.salesInvoice.billing_entity_type == _vm.BILLING_ENTITY_TYPE_INDIVIDUAL
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.FIRSTNAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesInvoice.billing_firstname),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "billing_firstname", $$v)},expression:"salesInvoice.billing_firstname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_firstname}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.LASTNAME')},model:{value:(_vm.salesInvoice.billing_lastname),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "billing_lastname", $$v)},expression:"salesInvoice.billing_lastname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_lastname}})],1)]):_vm._e(),(_vm.salesInvoice.billing_entity_type == _vm.BILLING_ENTITY_TYPE_COMPANY)?_c('base-input',{attrs:{"label":_vm.$t('COMMON.COMPANY_NAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesInvoice.billing_company_name),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "billing_company_name", $$v)},expression:"salesInvoice.billing_company_name"}}):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_company_name}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EMAIL')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesInvoice.billing_email),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "billing_email", $$v)},expression:"salesInvoice.billing_email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_email}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.COUNTRY'),"placeholder":_vm.$t('COMMON.COUNTRY')}},[_c('country-selector',{attrs:{"country":_vm.salesInvoice.billing_country,"filterable":true,"showAll":false},on:{"countryChanged":function (country) {
              _vm.salesInvoice.billing_country = country;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_country}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.STATE'),"placeholder":_vm.$t('COMMON.STATE')}},[_c('state-selector',{attrs:{"country":_vm.salesInvoice.billing_country,"state":_vm.salesInvoice.billing_state,"filterable":true,"showAll":false},on:{"stateChanged":function (state) {
              _vm.salesInvoice.billing_state = state;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_state}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.CITY')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesInvoice.billing_city),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "billing_city", $$v)},expression:"salesInvoice.billing_city"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_city}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.ZIPCODE')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesInvoice.billing_zipcode),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "billing_zipcode", $$v)},expression:"salesInvoice.billing_zipcode"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_zipcode}})],1)]),_c('base-input',{attrs:{"label":_vm.$t('COMMON.ADDRESS')},on:{"change":_vm.onFormChanged},model:{value:(_vm.salesInvoice.billing_address),callback:function ($$v) {_vm.$set(_vm.salesInvoice, "billing_address", $$v)},expression:"salesInvoice.billing_address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.billing_address}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.salesInvoice.id ? _vm.$t("SALES_INVOICES.EDIT_SALES_INVOICE") : _vm.$t("SALES_INVOICES.ADD_SALES_INVOICE"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }